@charset "UTF-8";

// ===========================
// Reset
// ===========================
@import "./lib/sanitize";

:root, html, body {
    background-color: $color-base;
    color: $color-main-text;
    width: 100%;
}
* {
    box-sizing: border-box;
}

li {
    list-style-type: none;
}

a {
    color: $color-main-text;
    text-decoration: none;
}

a:visited {
  color: $color-visited;
}

a:hover {
    text-decoration: none;
    color: $color-link;
}

a:hover img {
  opacity: 0.7;
}
